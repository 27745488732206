import { Info } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Grid, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { TestDetail } from '../../../../../core/submissions/types';
import CPUCell from '../../../../common/tableCellRenderers/itemComponents/CPUCell';
import MemoryCell from '../../../../common/tableCellRenderers/itemComponents/MemoryCell';
import VerdictChip from '../../../../common/VerdictChip';
import CodeViewer from '../../../../../design-library/CodeViewer';
import { getFileFromUrl } from '../../../../../core/commons/externalApi';
import { enqueueSnackbar } from 'notistack';
import { LinearLoader } from '../../../../../design-library/LinearLoader';
import ReactDiffViewer from 'react-diff-viewer';
import MonacoDiffEditor from '../../../../../design-library/MonacoDiffEditor';

interface Props {
	isJudge: boolean;
	testDetail: TestDetail;
	index: number;
}

function TestDetailsTableRow({ isJudge, testDetail, index }: Props) {
	const [showStderr, setShowStderr] = React.useState(false);
	const [showDiff, setShowDiff] = React.useState(false);
	const hasStderr = !!testDetail.stderr;

	const handleShowDiff = () => {
		if (isJudge) setShowDiff(true);
	};

	return (
		<TableRow>
			<TableCell>
				<div style={isJudge ? { textDecoration: 'underline', cursor: 'pointer' } : {}} onClick={handleShowDiff}>
					{index + 1}
				</div>
			</TableCell>
			<TableCell>
				<CPUCell cpu={testDetail.cpu} />
			</TableCell>
			<TableCell>
				<MemoryCell memory={testDetail.memory.toFixed(2)} />
			</TableCell>
			<TableCell sx={{ display: 'flex', justifyContent: 'start' }}>
				<div>
					<VerdictChip
						status={testDetail.status === 'QUEUED' ? 'RUNNING' : testDetail.status}
						verdict={testDetail.response}
						type={'SINGLE_RUN'}
					/>
				</div>
				{hasStderr && (
					<Tooltip title={'Click here to view error details!'}>
						<div onClick={() => setShowStderr(true)}>
							<Info color={'error'} />
						</div>
					</Tooltip>
				)}
			</TableCell>
			<Dialog key={'stderr'} open={showStderr} onClose={() => setShowStderr(false)} fullWidth maxWidth={'lg'}>
				<DialogTitle>Stderr response</DialogTitle>
				<DialogContent>
					<CodeViewer language={'cpp'} value={testDetail.stderr} />
				</DialogContent>
			</Dialog>
			<Dialog key={'output-diff'} open={showDiff} onClose={() => setShowDiff(false)} fullWidth maxWidth={'lg'}>
				<DialogTitle>Output diff</DialogTitle>
				<DialogContent>
					<Typography>
						<a href={testDetail.stdout_url} rel='noreferrer' target='_blank' download>
							Download contestant output file
						</a>
					</Typography>
					<Typography>
						{testDetail.test_case ? (
							<a href={testDetail.test_case.output_url} rel='noreferrer' target='_blank' download>
								Download judge output file
							</a>
						) : null}
					</Typography>
					<OutputDiffViewer judgeOutputUrl={testDetail?.test_case?.output_url} stdoutUrl={testDetail.stdout_url} />
				</DialogContent>
			</Dialog>
		</TableRow>
	);
}

function OutputDiffViewer({ judgeOutputUrl, stdoutUrl }: { judgeOutputUrl: string; stdoutUrl: string }) {
	const [judgeOutput, setJudgeOutput] = React.useState<string>('');
	const [stdOutput, setStdOutput] = React.useState<string>('');
	const [judgeOutputLoading, setJudgeOutputLoading] = React.useState<boolean>(false);
	const [stdOutputLoading, setStdOutputLoading] = React.useState<boolean>(false);
	const [showDiff, setShowDiff] = React.useState<boolean>(true);

	useEffect(() => {
		setJudgeOutputLoading(true);
		getFileFromUrl(judgeOutputUrl)
			.then((data) => {
				setJudgeOutput(data);
			})
			.catch(() => {
				enqueueSnackbar('Some error occurred. Please reload the page', { variant: 'error' });
			})
			.finally(() => setJudgeOutputLoading(false));
	}, []);

	useEffect(() => {
		setStdOutputLoading(true);
		getFileFromUrl(stdoutUrl)
			.then((data) => {
				setStdOutput(data);
			})
			.catch(() => {
				enqueueSnackbar('Some error occurred. Please reload the page', { variant: 'error' });
			})
			.finally(() => setStdOutputLoading(false));
	}, []);

	if (judgeOutputLoading || stdOutputLoading) return <LinearLoader />;

	return (
		<Grid container>
			{/*<Grid item>*/}
			{/*	<FormControlLabel*/}
			{/*		value='diffSwitch'*/}
			{/*		control={<Switch color='primary' checked={showDiff} onChange={() => setShowDiff(!showDiff)} />}*/}
			{/*		label='Show differences between stdout vs judge output'*/}
			{/*		labelPlacement='end'*/}
			{/*		sx={{ color: '#2F5D62' }}*/}
			{/*	/>*/}
			{/*</Grid>*/}
			{showDiff && (
				<Grid item sx={{ width: '100%' }}>
					<MonacoDiffEditor original={stdOutput} modified={judgeOutput} />
				</Grid>
			)}
		</Grid>
	);
}

export default TestDetailsTableRow;
