import React, { useState } from 'react';
import { SyntheticEvent } from 'react';
import { Grid, Tabs, Tab } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import { SectionTitle } from '../../design-library';
import { Contest } from '../../core/contests/types';
import ContestConfig from './Contest/ContestConfig';
import Judges from './Contest/Judges';
import CsvFileUploader from './CreateUsers/FIleUploader';

export default function ContestForm({
	type,
	initFormData,
	handleSubmit,
}: {
	type: 'create' | 'edit';
	initFormData: Contest;
	handleSubmit: (data: Contest) => Promise<Contest>;
}) {
	const [value, setValue] = useState('1');

	const handleChange = (event: SyntheticEvent<Element, Event>, newValue: string) => {
		setValue(newValue);
	};

	return (
		<Grid container alignItems='center' justifyContent='center'>
			<Grid item container direction='row' xs={8} sx={{ minWidth: '450px' }} rowSpacing={'2px'}>
				<Grid item xs={12}>
					<SectionTitle title={`${type === 'create' ? 'Create new' : 'Edit'} contest`} size='small' />
				</Grid>
				<TabContext value={value}>
					<Grid item xs={12}>
						<Tabs value={value} onChange={handleChange} aria-label='contest tabs'>
							<Tab label='Config' value='1' />
							<Tab label='Judges' value='2' />
							<Tab label='Contestants' value='3' />
						</Tabs>
					</Grid>
					<TabPanel value='1'>
						<ContestConfig type={type} initFormData={initFormData} handleSubmit={handleSubmit} />
					</TabPanel>
					<TabPanel value='2'>
						<Judges contestId={initFormData.id} />
					</TabPanel>
					<TabPanel value='3'>
						<CsvFileUploader contest_id={initFormData.id!} />
					</TabPanel>
				</TabContext>
			</Grid>
		</Grid>
	);
}
