import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Problem } from '../../../core/problems/types';
import { ProblemErrors } from './types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createNewProblem } from '../../../core/problems/services';

export default function CreateProblemDialog({
	open,
	setOpen,
	contestId,
	contestSlug,
}: {
	open: boolean;
	setOpen: (isOpen: boolean) => void;
	contestId: number;
	contestSlug: string;
}) {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const initFormData: Problem = {
		// slug: '',
		title: '',
	};
	const [formData, setFormData] = useState<Problem>(initFormData);
	const [errorData, setErrorData] = useState<ProblemErrors>({});
	const [isLoading, setIsLoading] = useState(false);

	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = event.target;
		let updatedValue = type === 'checkbox' ? checked : value;
		if (name === 'slug') {
			updatedValue = (value as string).replace(/\s+/g, '-').toLowerCase();
		}

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: updatedValue,
		}));
	};

	const onCreateHandler = async () => {
		try {
			setIsLoading(true);
			const response = await createNewProblem(contestId, formData);
			enqueueSnackbar(`Problem "${response.title}" created!`, {
				variant: 'success',
			});
			navigate(`/contests/${contestSlug}/problems/${response.problem_set!.order_character}`);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				setErrorData(e.response?.data ?? {});
				enqueueSnackbar(`Problem could not be created!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Create new problem</DialogTitle>
			<DialogContent sx={{ minWidth: '600px', backgroundColor: 'white' }}>
				<DialogContentText>Create a new problem for this contest.</DialogContentText>
				<Grid
					container
					direction='column'
					justifyContent='center'
					sx={{ width: '100%', padding: '35px', gap: 2 }}
					component={'form'}
					onSubmit={(e) => {
						e.preventDefault();
						onCreateHandler();
					}}
				>
					<TextField
						id='problem-title'
						name='title'
						required
						label='Problem title'
						variant='outlined'
						value={formData.title}
						onChange={handleValueChange}
						error={!!errorData.title}
						helperText={errorData.title}
						fullWidth
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant='outlined' onClick={handleClose}>
					Cancel
				</Button>
				<Button type='submit' disabled={isLoading} variant='contained' onClick={onCreateHandler}>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
}
