import React from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { UploadedFile, UploadedFileStatus } from '../../../core/contestants/types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import serverApi from '../../../core/serverApi';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EmailIcon from '@mui/icons-material/Email';
import { enqueueSnackbar } from 'notistack';

const truncateString = (str: string, length: number) => {
	return str.length > length ? '...' + str.slice(-length + 3) : str;
};

function UserCreationActions({
	fileId,
	fileStatus,
	isEnabled,
}: {
	fileId: number;
	fileStatus: UploadedFileStatus;
	isEnabled: boolean;
}) {
	const createUsers = async () => {
		try {
			await serverApi.get(`api/judge/contest-bulk-user-generation/${fileId}/create-users/`);
			enqueueSnackbar('User Creation Started! Please Reload page for updated status', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Something went wrong! Please reload page and try again!', { variant: 'error' });
		}
	};

	const emailUsers = async () => {
		try {
			await serverApi.get(`api/judge/contest-bulk-user-generation/${fileId}/email-users/`);
			enqueueSnackbar('Sending Email Started! Please Reload page for updated status', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Something went wrong! Please reload page and try again!', { variant: 'error' });
		}
	};

	return (
		<Grid container direction='row'>
			<Grid item>
				<Tooltip title='Create Users'>
					<IconButton disabled={!isEnabled || fileStatus !== 'VALIDATED'} onClick={createUsers}>
						<GroupAddIcon />
					</IconButton>
				</Tooltip>
			</Grid>{' '}
			<Grid item>
				<Tooltip title='Email Users'>
					<IconButton disabled={fileStatus !== 'USER_GENERATION_COMPLETED'} onClick={emailUsers}>
						<EmailIcon />
					</IconButton>
				</Tooltip>
			</Grid>
		</Grid>
	);
}

export default function UploadedFilesTable({ files }: { files: UploadedFile[] }) {
	if (!files) return <div>No files found!</div>;
	return (
		<Grid container>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell align='right'>File path</TableCell>
							<TableCell align='right'>Uploaded file</TableCell>
							<TableCell align='right'>Validated file</TableCell>
							<TableCell align='right'>Progress</TableCell>
							<TableCell align='right'>Status</TableCell>
							<TableCell align='right' style={{ width: '120px' }}>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{files.map((file) => (
							<TableRow key={file.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component='th' scope='row'>
									{file.id}
								</TableCell>
								<TableCell align='right'>{truncateString(file.file_path, 30)}</TableCell>
								<TableCell align='right'>
									<a href={file.uploaded_file_url}>Uploaded file</a>
								</TableCell>
								<TableCell align='right'>
									<a href={file.validated_file_url}>
										{file.validated_file_url ? 'Validated file' : 'Not validated yet'}
									</a>
								</TableCell>
								<TableCell align='right'>{file.progress}%</TableCell>
								<TableCell align='right'>{file.status}</TableCell>
								<TableCell align='right'>
									<UserCreationActions fileId={file.id} fileStatus={file.status} isEnabled={file.is_enabled} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
}
