import { ContestJudgesResult } from '../../components/manager/types';
import serverApi from '../serverApi';
import createDummyContests from './dummyData/dummyContests';
import { Contest, ContestRead, PaginatedContests } from './types';

const contestsEndpoint = '/api/judge/contests';
const usersEndpoint = '/api/user';

const getContestData = async (contestSlugOrId: string | number) => {
	return (await serverApi.get(`${contestsEndpoint}/${contestSlugOrId}/`)).data as ContestRead;
};

const getContests = async (params: { limit?: number; offset?: number }) => {
	return (
		await serverApi.get(`${contestsEndpoint}/`, {
			params,
		})
	).data as PaginatedContests;
};

const getPaginatedContests = async () => {
	return createDummyContests() as PaginatedContests; // TODO: Remove when api integrated
	return (await serverApi.get(`${contestsEndpoint}/`)).data as PaginatedContests;
};

const createNewContest = async (contest: Contest) => {
	return (await serverApi.post(`${contestsEndpoint}/`, contest)).data as Contest;
};

const editContest = async (contestId: number, contest: Contest) => {
	return (await serverApi.patch(`${contestsEndpoint}/${contestId}/`, contest)).data as Contest;
};

const getJudgesOfAContest = async (contestId: number | undefined) => {
	return (await serverApi.get(`${contestsEndpoint}/${contestId}/judges/`)).data as ContestJudgesResult;
};

const addJudgeToAContest = async (contestId: number | undefined, user: number) => {
	return (
		await serverApi.post(`${contestsEndpoint}/${contestId}/add-user/`, {
			user,
			role: 'JUDGE',
		})
	).data;
};

const searchUsers = async (query: string) => {
	return (await serverApi.get(`${usersEndpoint}/users?search=${query}&limit=5&offset=0`)).data;
};

export {
	getContestData,
	getContests,
	getPaginatedContests,
	createNewContest,
	editContest,
	getJudgesOfAContest,
	addJudgeToAContest,
	searchUsers,
};
