import serverApi from '../serverApi';
import { AuthorizationResponse } from './types';
import { loginEndpoint, updatePasswordEndpoint } from './constants';

const login = async (username: string, password: string) => {
	const response = await serverApi.post(`${loginEndpoint}/`, {
		username,
		password,
	});
	return response.data as AuthorizationResponse;
};

const updatePassword = async (current_password: string, new_password: string, re_new_password: string) => {
	const response = await serverApi.post(`${updatePasswordEndpoint}/`, {
		new_password,
		re_new_password,
		current_password,
	});
	return response.data;
};

export { login, updatePassword };
