import React, { useEffect } from 'react';
import announcementText from './announcement.md';
import MarkdownView from '../../design-library/MarkdownView';
import { Grid, Typography, Box } from '@mui/material';
import { SectionPaper } from '../../design-library';

export default function Announcement() {
	const [markdownText, setMarkdownText] = React.useState<string | null>(null);
	useEffect(() => {
		fetch(announcementText)
			.then((response) => response.text())
			.then((text) => setMarkdownText(text));
	}, []);

	if (!markdownText) {
		return null;
	}

	console.log(
		'======================================================================================================================',
	);
	console.log(
		'==================================== WHOEVER-IS-TRYING-TO-HACK/BREAK-THE-SYSTEM ======================================',
	);
	console.log(
		'=================================== GO s--k your own c--k (assuming you have one) ====================================',
	);
	console.log(
		'======================================================================================================================',
	);

	return (
		<Grid container direction={'column'} xs={12} justifyContent={'center'} sx={{ padding: '0 0 24px 0' }}>
			<SectionPaper>
				<Grid item container justifyContent={'center'}>
					<Typography align={'center'} variant={'h4'} mb={1} color={'secondary'}>
						Announcement
					</Typography>
				</Grid>
				<Box sx={{ width: '100%', paddingX: '20px', marginTop: '20px' }}>
					<MarkdownView data={markdownText} />
				</Box>
			</SectionPaper>
		</Grid>
	);
}
