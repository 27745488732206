import serverApi from '../serverApi';
import { BulkUserCreationData } from './types';

const bulkUserCreationApi = '/api/judge/contest-bulk-user-generation';

const getBulkUserCreationData = async (contestId: string | number) => {
	return (await serverApi.get(`${bulkUserCreationApi}/`, { params: { contest: contestId } }))
		.data as BulkUserCreationData;
};

export { getBulkUserCreationData };
