import React, { useState } from 'react';
import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField,
	Button,
	CircularProgress,
	AccordionSummary,
	Typography,
	Accordion,
} from '@mui/material';
import { SectionPaper } from '../../../design-library';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Contest, ContestType, ContestVisibilityType, StandingType } from '../../../core/contests/types';
import {
	CONTEST_TYPE_LABELS,
	CONTEST_TYPES,
	CONTEST_VISIBILITY_TYPE_LABELS,
	CONTEST_VISIBILITY_TYPES,
	STANDING_TYPE_LABELS,
	STANDING_TYPES,
} from '../../../core/contests/constants';
import { DateTimePicker } from '@mui/x-date-pickers';
import IOSSwitch from '../../common/IOSSwitch';
import { ContestErrors } from '../types';
import axios from 'axios';
import { Moment } from 'moment';
import moment from 'moment/moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MarkdownEdit from '../../../design-library/MarkdownEdit';

export default function ContestConfig({
	type,
	initFormData,
	handleSubmit,
}: {
	type: 'create' | 'edit';
	initFormData: Contest;
	handleSubmit: (data: Contest) => Promise<Contest>;
}) {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [formData, setFormData] = useState<Contest>(initFormData);
	const [errorData, setErrorData] = useState<ContestErrors>({});
	const [isLoading, setIsLoading] = useState(false);

	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = event.target;
		let updatedValue = type === 'checkbox' ? checked : value;
		if (name === 'slug') {
			updatedValue = (value as string).replace(/\s+/g, '-').toLowerCase();
		}

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: updatedValue,
		}));
	};

	const handleNamedValueChange = (name: keyof Contest, value?: string) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSelectChange = (event: SelectChangeEvent<ContestType | ContestVisibilityType | StandingType>) => {
		const { name, value } = event.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleDateTimeChange = (name: 'starts_at' | 'ends_at', value: Moment | string | null) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const onSubmitHandler = async () => {
		try {
			setIsLoading(true);
			const response = await handleSubmit(formData);
			setFormData({
				...response,
				starts_at: moment(response.starts_at),
				ends_at: moment(response.ends_at),
			});
			enqueueSnackbar(`Contest "${response.title}" ${type === 'create' ? 'created' : 'edited'}!`, {
				variant: 'success',
			});
			if (type === 'create') navigate(`/contests/${response.slug}`);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				setErrorData(e.response?.data ?? {});
				enqueueSnackbar(`Contest could not be ${type === 'create' ? 'created' : 'edited'}!`, { variant: 'error' });
			} else {
				enqueueSnackbar(`Something went wrong!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Grid item xs={12}>
			<SectionPaper>
				<Grid
					container
					direction='column'
					justifyContent='center'
					sx={{ width: '100%', padding: '35px', gap: 2 }}
					component={'form'}
				>
					<TextField
						id='contest-slug'
						name='slug'
						required
						label='Contest slug'
						variant='outlined'
						value={formData.slug}
						onChange={handleValueChange}
						error={!!errorData.slug}
						helperText={errorData.slug}
					/>
					<FormControl sx={{ width: '100%' }} error={!!errorData.contest_type}>
						<InputLabel id='select-contest-type-label' required>
							Type
						</InputLabel>
						<Select
							labelId='select-contest-type-label'
							name='contest_type'
							id='select-contest-type'
							required
							value={formData.contest_type}
							label='Type'
							onChange={handleSelectChange}
						>
							{Object.values(CONTEST_TYPES).map((contestType: ContestType) => (
								<MenuItem key={contestType} value={contestType}>
									{CONTEST_TYPE_LABELS[contestType]}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{errorData.contest_type}</FormHelperText>
					</FormControl>
					<TextField
						id='contest-title'
						name='title'
						required
						label='Contest title'
						variant='outlined'
						value={formData.title}
						onChange={handleValueChange}
						error={!!errorData.title}
						helperText={errorData.title}
					/>
					<TextField
						id='contest-description'
						name='description'
						required
						label='Contest description'
						variant='outlined'
						value={formData.description}
						multiline
						onChange={handleValueChange}
						error={!!errorData.description}
						helperText={errorData.description}
					/>
					<Accordion key='notes'>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Public Notes</Typography>
						</AccordionSummary>
						<MarkdownEdit
							value={formData.notes}
							setValue={(value?: string) => handleNamedValueChange('notes', value)}
						/>
						{!!errorData.notes && <FormHelperText error>{errorData.notes ?? ''}</FormHelperText>}
					</Accordion>
					<DateTimePicker
						label='Starts at *'
						format={'DD MMM YYYY HH:mm'}
						ampm={false}
						value={formData.starts_at}
						onChange={(value, _) => handleDateTimeChange('starts_at', value)}
						slotProps={{
							textField: {
								error: !!errorData.starts_at,
								helperText: errorData.starts_at,
							},
						}}
					/>
					<DateTimePicker
						disabled
						label='Ends at'
						format={'DD MMM YYYY HH:mm'}
						ampm={false}
						value={formData.ends_at}
						slotProps={{
							textField: {
								error: !!errorData.ends_at,
								helperText: errorData.ends_at || 'Automatically updated from duration field after edit',
							},
						}}
					/>
					<FormControl fullWidth error={!!errorData.duration}>
						<InputLabel htmlFor='outlined-adornment-amount'>Duration</InputLabel>
						<OutlinedInput
							id='outlined-adornment-amount'
							name='duration'
							endAdornment={<InputAdornment position='end'>Hours</InputAdornment>}
							label='Duration'
							value={formData.duration}
							onChange={handleValueChange}
							type='number'
							inputProps={{
								step: 0.25,
								min: 1.0,
							}}
						/>
						<FormHelperText>{errorData.duration}</FormHelperText>
					</FormControl>
					<FormControl sx={{ width: '100%' }} error={!!errorData.contest_visibility}>
						<InputLabel id='select-contest-visibility-label'>Visibility</InputLabel>
						<Select
							labelId='select-contest-visibility-label'
							id='select-contest-visibility'
							name='contest_visibility'
							value={formData.contest_visibility}
							label='Visibility'
							onChange={handleSelectChange}
						>
							{Object.values(CONTEST_VISIBILITY_TYPES).map((contestVisibilityType: ContestVisibilityType) => (
								<MenuItem key={contestVisibilityType} value={contestVisibilityType}>
									{CONTEST_VISIBILITY_TYPE_LABELS[contestVisibilityType]}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{errorData.contest_visibility}</FormHelperText>
					</FormControl>
					<FormControl sx={{ width: '100%' }} error={!!errorData.standing_type}>
						<InputLabel id='select-standing-type-label'>Standing type</InputLabel>
						<Select
							labelId='select-standing-type-label'
							id='select-standing-type'
							name='standing_type'
							value={formData.standing_type}
							label='Standing type'
							onChange={handleSelectChange}
						>
							{Object.values(STANDING_TYPES).map((standingType: StandingType) => (
								<MenuItem key={standingType} value={standingType}>
									{STANDING_TYPE_LABELS[standingType]}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{errorData.standing_type}</FormHelperText>
					</FormControl>
					<FormControlLabel
						control={
							<IOSSwitch sx={{ m: 1 }} name='is_frozen' checked={formData.is_frozen} onChange={handleValueChange} />
						}
						sx={{ width: '100%', justifyContent: 'center' }}
						label='Frozen'
					/>

					<Button disabled={isLoading} variant='contained' onClick={onSubmitHandler} size='large'>
						{isLoading ? <CircularProgress size={25} /> : `${type === 'create' ? 'Create' : 'Edit'} Contest`}
					</Button>
				</Grid>
			</SectionPaper>
		</Grid>
	);
}
