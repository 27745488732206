import React, { useEffect } from 'react';
import { Grid, List, ListItemButton, Pagination } from '@mui/material';
import { Contest } from '../../../core/contests/types';
import { ContestItem } from './ContestItem';
import { SectionTitle } from '../../../design-library';
import { useNavigate } from 'react-router-dom';
import { getContests } from '../../../core/contests/services';
import { useTitle } from '../../../contexts/TitleContext';
import Announcement from '../../Homepage/Announcement';

export default function ContestList() {
	const { setTitle } = useTitle();
	setTitle('Contests');
	const PAGE_SIZE = 25;
	const [page, setPage] = React.useState(1);
	const [contests, setContests] = React.useState<Contest[]>([]);
	const navigate = useNavigate();
	const [count, setCount] = React.useState(0);
	const [offset, setOffset] = React.useState(0);

	useEffect(() => {
		const fetchContests = async () => {
			const contestsData = await getContests({ offset, limit: PAGE_SIZE });
			setContests(contestsData.results);
			setCount(contestsData.count);
		};
		fetchContests();
	}, [offset]);

	const onPageChangeHandler = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
		setOffset((value - 1) * PAGE_SIZE);
	};

	const handleContestOpen = (contestSlug: string) => {
		navigate(`/contests/${contestSlug}`);
	};

	return (
		<Grid container direction='row'>
			<Grid item xs={12}>
				<Announcement />
			</Grid>
			<Grid item xs={12}>
				<SectionTitle title={'Contests'} size={'small'} />
				<List>
					{contests.map((contest, key) => (
						<ListItemButton
							key={key}
							sx={{ border: 1, margin: '2px 0px', borderColor: 'rgba(0, 0, 0, 0.07)' }}
							onClick={() => handleContestOpen(contest.slug)}
						>
							<ContestItem contest={contest} />
						</ListItemButton>
					))}
				</List>
			</Grid>
			<Grid item container xs={12} direction='column' justifyContent='center' alignItems='center'>
				<Pagination
					page={page}
					count={Math.ceil(count / PAGE_SIZE)}
					variant='outlined'
					shape='rounded'
					size='large'
					onChange={onPageChangeHandler}
					boundaryCount={1}
				/>
			</Grid>
		</Grid>
	);
}
